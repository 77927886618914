<template>
  <div v-if="isCustomHTML" class="h-screen overflow-y-hidden">
    <iframe :src="customUrl" width="100%" height="100%" frameborder="0"></iframe>
  </div>
	<div v-else class="page-event-live" ref="paretLive" id="parentx" :style="eventFont">
    <div v-show="!isFetching && userEmail || !isFetching && !isRegisterEnabled || !isFetching && userEmail && isRegisterEnabled" >
      <!-- Mobile - Open Menu -->
      <div class="sm:inline-flex xl:hidden cursor-pointer px-6 pt-6">
        <feather-icon
          icon="MenuIcon"
          @click.stop="toggleSidebar"
          v-if="!isEventNotAvailable"
        />
      </div>

      <!-- Mobile - Sidebar Menu -->
      <vs-sidebar
        parent=".layout--main"
        default-index="-1"
        color="primary"
        class="sidebarx live-sidebar"
        ref="paretLiveSidebar"
        spacer
        v-model="isShowSidebar"
        v-if="event">
        <!-- Logo -->
        <div class="mobile-logo-wrapper flex w-full">
          <!-- Logo -->
          <img :src="event.logo" alt="Kobla" class="pr-5 max-w-250 pl-2" v-if="event.logo && event.logo !== '@/assets/images/logo/blank.png'" />
          <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 max-w-250 pl-2" v-if="!event.logo" />
        </div>

        <div class="px-4 pt-4 text-sm text-dark-gray text-xl">
          {{ event.name }}
        </div>

        <div class="p-4 mt-2">
          <!-- Date -->
          <div class="text-sm">
            <div class="font-semibold">
              {{ formatEventTime }}
            </div>
            <div class="font-semibold mt-1">
              #{{ event.code }}
            </div>
          </div>

          <!-- Menus -->
          <div class="mt-20 space-y-6">
            <div class="cursor-pointer text-dark-gray text-lg font-semibold" @click="showHome()">
              {{ $t('Event Home') }}
            </div>
            <div class="cursor-pointer text-dark-gray text-lg font-semibold" @click="showProfile()">
              {{ $t('My Profile') }}
            </div>
            <div v-if="isShowMenuSwitchQuestion" class="cursor-pointer text-dark-gray text-lg font-semibold" @click="switchEvent()">
              {{ $t('Switch Event') }}
            </div>
            <div v-if="isShowMenuSwitchQuestion" class="cursor-pointer text-dark-gray text-lg font-semibold" @click="showMyQuestion()">
              {{ $t('My Question') }}
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="p-4 mobile-live-footer">
          <!-- <div class="space-y-2">
            <div class="cursor-pointer text-black" @click="switchEvent()" v-tooltip="$t('Switch to another event, you will be logged out from this event')">
              {{ $t("Switch Event") }}
            </div>
            <div>
              <a class="cursor-pointer text-black" href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </div>
            <div>
              <a class="cursor-pointer text-black" href="/terms-and-conditions" target="_blank">
                Terms and Conditions
              </a>
            </div>
          </div> -->
        </div>
      </vs-sidebar>

      <!-- Page content -->
      <div v-if="event"
        class="page-wrapper"
        :class="{'interactive-mode': isInteractiveMode, 'pt-0': windowWidth < 1199}">
        <div class="logo-wrapper xl:flex">

          <div>
            <!-- Logo -->
            <img :src="event.logo" alt="Kobla" class="pr-5 hidden xl:block" v-if="event.logo && event.logo !== '@/assets/images/logo/blank.png'" />
            <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 hidden xl:block" v-if="!event.logo" />
          </div>

          <!-- Profile dropdown -->
          <profile-drop-down
            ref="profileDropdown"
            :is-live-menu="true"
            :event="event"
            :eventSettings="eventSettings"
            @switchEvent="switchEvent"
            v-if="user"
            style="z-index: 999"
          />
        </div>

        <!-- Content -->
        <div class="mt-5" :class="{'container': !isFullscreen}">
          <!-- Header Name -->
          <div class="p-2 hidden xl:block" v-if="!isFullscreen">
            <vs-row :vs-w="isLiveVideoVisible && (!isQuestionPaneVisible && !isPollsVisible) ? 8 : 12"
            :style="isLiveVideoVisible && !isFullscreen && (!isQuestionPaneVisible && !isPollsVisible) ? 'margin: auto' : ''">
            <!-- showing name on top if !isCountdown -->
              <vs-col vs-sm="12" v-if="!isCountdownVisible">
                <div :class="`${!isLiveVideoVisible ? 'video-details-container' : ''}`">
                  <div v-if="!isInteractiveMode">
                    <h2 :style="colorPage" class="text-xl lg:text-3xl">{{ event.name }}</h2>
                  </div>
                  <div
                    v-else
                    :class="`${isInteractiveMode ? 'text-center' : ''}`"
                    class="mb-6"
                    >
                    <h3 v-if="isQuestionPaneVisible || isPollsVisible" :style="colorPage" class="text-xl lg:text-3xl">{{ event.name }}</h3>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <!-- Mobile -->
          <div class="xl:hidden" v-if="isMobile">
             <!-- Live Countdown -->
             <div class="video-pre-embed" v-if="isCountdownVisible">
                <pre-live-event
                  :date="event.start_date"
                  :timeZone="event.timezone"
                  :show="isCountdownVisible"
                  :eventSettings="eventSettings"
                  :event="event"
                  @showCountdown="showCountdown"
                  >
                </pre-live-event>
              </div>

            <!-- Filter -->
            <div class="flex items-center filter-tabs" :style="colorPage" v-if="!isCountdownVisible">
              <!-- if qna && video is empty -->
              <div v-if="isInteractiveMode && !isQuestionPaneVisible && !isPollsVisible && !isCountdownVisible" class="text-center question-pane-fullscreen-interactive fixed">
                <h2 :style="colorPage" class="text-xl lg:text-3xl mb-4 mt-20">{{ event.name }}</h2>
                {{ $t('Viewer interaction are disabled at the moment') }}
              </div>
              <div class="flex space-x-4 justify-between w-full">
                <div class="filter-tab w-full text-center"
                  :class="`${mobileFilterTab === 'video' ? 'selected' : ''}`"
                  @click="mobileFilterTab = 'video'"
                  v-if="!isInteractiveMode && (isQuestionPaneVisible || isPollsVisible)">
                  Video
                </div>
                <div
                  class="filter-tab w-full text-center"
                  :class="`${mobileFilterTab === 'qna' ? 'selected' : ''}`"
                  @click="mobileFilterTab = 'qna'"
                  v-if="isQuestionPaneVisible && !isInteractiveMode || isQuestionPaneVisible && isInteractiveMode && isPollsVisible">
                  {{ $t("Q&A") }}
                </div>
                <div
                  class="filter-tab w-full text-center"
                  :class="`${mobileFilterTab === 'polls' ? 'selected' : ''}`"
                  @click="mobileFilterTab = 'polls'"
                  v-if="isPollsVisible && !isInteractiveMode || isPollsVisible && isInteractiveMode && isQuestionPaneVisible">
                  {{ $t("Poll") }}
                </div>
              </div>
            </div>

            <div
              class="p-2 interactive-container"
              v-if="!isCountdownVisible"
              >
              <!-- Video -->
              <div
                class="video-embed"
                :style="colorPage"
                :class="`${mobileFilterTab !== 'video' ? 'hidden' : ''}`">
                <!-- Video -->
                <live-embed-code
                  :event="event"
                  :id="'liveEmbedCode'"
                  v-if="isLiveVideoVisible && isEmbedCodeExist">
                </live-embed-code>

                <!-- Details -->
                <div class="mt-4" :class="`${isBoxBackgroundEnabled ? 'mobile-interactive-container bg-white p-4 rounded' : ''}`">
                  <live-event-details
                    :event="event"
                    :eventSettings="eventSettings">
                  </live-event-details>
                </div>
              </div>

              <!-- Q&A -->
              <div
                v-if="mobileFilterTab === 'qna' && isQuestionPaneVisible"
                :class="`${isBoxBackgroundEnabled ? 'mobile-interactive-container bg-white p-4 rounded' : ''}`">
                <!-- Active Announcement -->
                <active-announcement
                  :event="event"
                />

                <!-- Question List -->
                <question-list
                  :event="event"
                  :participant="participant"
                  :eventSettings="eventSettings">
                </question-list>
              </div>

              <!-- Polls -->
              <div
                v-if="mobileFilterTab === 'polls'"
                :class="`${isBoxBackgroundEnabled ? 'mobile-interactive-container bg-white p-4 rounded' : ''}`">
                <polling-box
                  :event="event"
                  :participant="participant"
                  :eventSettings="eventSettings">
                </polling-box>
              </div>
            </div>
          </div>

          <!-- Desktop - Contents -->
          <vs-row  class="hidden xl:flex" vs-w="12" v-if="!isMobile">
            <!-- Live Countdown -->
            <vs-row vs-w="8" class="flex justify-center" style="margin: auto;">
              <div class="video-pre-embed" style="max-height: calc(100vh - 100px); overflow-y: auto; width: 100%;">
                <!-- landing page before event is started -->
                <div :style="colorPage" v-if="isCountdownVisible">
                  <pre-live-event
                    :date="event.start_date"
                    :timeZone="event.timezone"
                    :show="isCountdownVisible"
                    :eventSettings="eventSettings"
                    :event="event"
                    @showCountdown="showCountdown"
                    >
                  </pre-live-event>
                </div>
              </div>
            </vs-row>

            <!-- Left -->
            <vs-col class="flex justify-center"
              :vs-lg="isLiveVideoVisible && !isFullscreen && (isQuestionPaneVisible || isPollsVisible)? 8 :
              !isLiveVideoVisible && !isFullscreen ? 12 :
              isLiveVideoVisible && !isFullscreen && (!isQuestionPaneVisible && !isPollsVisible) ? 12 : ''"
              vs-sm="12" v-if="!isInteractiveMode && !isCountdownVisible"
              :style="isFullscreen && (isQuestionPaneVisible || isPollsVisible) ? 'width: calc(100% - 400px);' :
              isFullscreen && (!isQuestionPaneVisible && !isPollsVisible) ? 'width: 100%' :
              ''">
              <div style="max-height: calc(100vh - 100px); overflow-y: auto; width: 100%;" :class="{'video-details-container': !isLiveVideoVisible}">
                <!-- Video -->
                <vs-row :class="{'container-iframe-live': isFullscreen}"
                :style="isLiveVideoVisible && !isFullscreen && (!isQuestionPaneVisible && !isPollsVisible) ? 'margin: auto;' : ''"
                :vs-w="isLiveVideoVisible && !isFullscreen && (!isQuestionPaneVisible && !isPollsVisible) ? 8 : 12"
                >
                  <vs-col ref="videoEmbed" class="video-embed" :style="colorPage" >
                    <div :style="isFullscreen ? 'background: #000000' : ''">
                      <!-- Video -->
                      <live-embed-code
                        :event="event"
                        :id="'liveEmbedCodeDesktop'"
                        :isFullscreen="isFullscreen"
                        v-if="isLiveVideoVisible && isEmbedCodeExist">
                      </live-embed-code>
                    </div>
                    <!-- title -->
                    <div v-if="isFullscreen" class="p-2 m-2 hidden xl:block">
                      <vs-row vs-w="12">
                      <!-- showing name on top if !isCountdown -->
                        <vs-col vs-sm="12" v-if="!isCountdownVisible">
                          <div :class="`${!isLiveVideoVisible ? 'video-details-container' : ''}`">
                            <div v-if="!isInteractiveMode">
                              <h2 :style="colorPage" class="text-xl lg:text-3xl font-bold">{{ event.name }}</h2>
                            </div>
                            <div
                              v-else
                              :class="`${isInteractiveMode ? 'text-center' : ''}`"
                              class="mb-6"
                              >
                              <h3 :style="colorPage" class="text-xl lg:text-3xl font-bold">{{ event.name }}</h3>
                            </div>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                    <!-- Details -->
                    <div v-if="event && event.description" class="mt-4" :class="{'bg-white p-4 rounded': isBoxBackgroundEnabled, 'm-3': isFullscreen}">
                      <live-event-details
                        :event="event"
                        :eventSettings="eventSettings">
                      </live-event-details>
                    </div>
                  </vs-col>
                </vs-row>

                <!-- Active polling -->
                <div class="mt-6 p-2" v-if="isShowActivePolling && isLiveVideoVisible">
                  <active-polling
                    :event="event"
                    :participant="participant"
                    :eventSettings="eventSettings">
                  </active-polling>
                </div>
              </div>
            </vs-col>
            <!-- Right -->
            <vs-col
              class="hidden xl:block"
              :class="{'pl-8': !isFullscreen, 'p-2': !isFullscreen && isQuestionPaneVisible && isPollsVisible}"
              :vs-lg="isInteractiveMode && !isFullscreen ? 12 : !isInteractiveMode && !isFullscreen ? 4 : ''"
              :style="isFullscreen ? 'width: 400px' : ''"
              vs-sm="12"
              v-if="isLiveVideoVisible || isInteractiveMode">
              <!-- if qna && video is empty -->
              <div v-if="isInteractiveMode && !isQuestionPaneVisible && !isPollsVisible && !isCountdownVisible" class="text-center question-pane-fullscreen-interactive fixed">
                <h2 :style="colorPage" class="text-xl lg:text-3xl mb-4">{{ event.name }}</h2>
                {{ $t('Viewer interaction are disabled at the moment') }}
              </div>
              <!-- interactive or with video -->
              <div
                class="interactive-container"
                :class="{'bg-white p-4 rounded': isBoxBackgroundEnabled,
                'question-pane-fullscreen': isFullscreen && (isQuestionPaneVisible || isPollsVisible),
                'question-pane-fullscreen-interactive fixed': isFullscreen && isInteractiveMode && (isQuestionPaneVisible || isPollsVisible)}"
                v-if="!isReloadTab && !isCountdownVisible && (isQuestionPaneVisible || isPollsVisible)">
                <!-- Filter -->
                <div v-if="isQuestionPaneVisible && isPollsVisible" class="flex items-center filter-tabs" :style="colorPage">
                  <div class="flex space-x-4 justify-between w-full">
                    <div class="filter-tab w-full text-center" :class="`${filterTab === 'qna' && isPollsVisible ? 'selected' : ''}`" @click="filterTab = 'qna'" v-if="isQuestionPaneVisible">
                      {{ $t("Q&A") }}
                    </div>
                    <div class="filter-tab w-full text-center"  :class="`${filterTab === 'polls' ? 'selected' : ''}`" @click="filterTab = 'polls'" v-if="isPollsVisible">
                      {{ $t("Poll") }}
                    </div>
                  </div>
                </div>

                <div>
                  <!-- QNA -->
                  <div v-if="filterTab === 'qna'">
                    <div class="w-full" :class="{'mt-4': isQuestionPaneVisible && isPollsVisible}">
                      <!-- Active Announcement -->
                      <active-announcement
                        :event="event"
                      />

                      <!-- Question list -->
                      <question-list
                        :event="event"
                        @getTotalQuestion="getTotalQuestion"
                        :participant="participant"
                        :eventSettings="eventSettings"
                        @setFilterTab="setFilterTab">
                      </question-list>
                    </div>
                  </div>

                  <!-- Polls -->
                  <div v-if="filterTab === 'polls'">
                    <div class="w-full" :class="{'mt-4': isQuestionPaneVisible && isPollsVisible}">
                      <!-- Polling box -->
                      <polling-box
                        :event="event"
                        :participant="participant"
                        :eventSettings="eventSettings">
                      </polling-box>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Loader -->
              <div class="p3 text-center" v-if="isReloadTab">
                <div class="is-loading large dark"></div>
              </div>
            </vs-col>
          </vs-row>
        </div>

        <!-- Footer -->
        <!-- <div class="live-footer hidden xl:block">
          <div class="flex space-x-8">
            <div class="cursor-pointer text-black" @click="switchEvent()" v-tooltip="$t('Switch to another event, you will be logged out from this event')">
              {{ $t("Switch Event") }}
            </div>
            <div>
              <a class="cursor-pointer text-black" href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </div>
            <div>
              <a class="cursor-pointer text-black" href="/terms-and-conditions" target="_blank">
                Terms and Conditions
              </a>
            </div>
          </div>
        </div> -->
      </div>

      <!-- If event exist -->
      <template v-if="event">

        <!-- Modal - Registration Required -->
        <modal-registration-required
          :show="isShowModalRegister"
          @close="isShowModalRegister = false">
        </modal-registration-required>

        <!-- Modal Access Code Required -->
        <modal-access-code-required
          :event="event"
          :participant="participant"
          :eventAccessCode="eventAccessCode"
          :show="isShowModalAccessCodeRequired"
          :isOnlyParticipantEvent="isOnlyParticipant"
          @setParticipant="setParticipant"
          @close="closeModalAccessCode">
        </modal-access-code-required>

        <ModalBroadcastMessage
          :data="broadcastData"
          :show="isShowBroadcast"
          @close="isShowBroadcast = false">
        </ModalBroadcastMessage>
      </template>

      <!-- Event Not Found -->
      <event-not-found
        v-if="!isFetching && !event && isEventNotAvailable">
      </event-not-found>
    </div>
    <div v-if="!isFetching && isRegisterEnabled && !userEmail">
      <register-container
      @refetchLandingPage="fetchEvent"
      :event="event"
      :eventSettings="eventSettings"/>
    </div>

    <!-- Loader -->
    <div class="p-10 mt-10 text-center" v-if="isFetching">
      <div class="is-loading large dark"></div>
    </div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { getAxiosErrorMessage } from '@/lib/helper';
import eventApi from '@/api/event';
import authApi from '@/api/auth';
import noLocale from '@/lib/locale/no';
import relativeTime from 'dayjs/plugin/relativeTime';

import LiveEventDetails from '@/components/LiveEventDetails.vue';
import ModalRegistrationRequired from '@/components/modals/ModalRegistrationRequired.vue';
import ModalAccessCodeRequired from '@/components/modals/ModalAccessCodeRequired.vue';
import EventNotFound from '@/components/errors/EventNotFound.vue';
import ProfileDropDown from '@/layouts/components/navbar/components/ProfileDropDown.vue';
import QuestionList from '@/components/questions/QuestionList.vue';
import PollingBox from '@/components/polls/PollingBox.vue';
import ActivePolling from '@/components/polls/ActivePolling.vue';
import LiveEmbedCode from '@/components/events/LiveEmbedCode.vue';
import ActiveAnnouncement from '@/components/announcements/ActiveAnnouncement.vue';
import PreLiveEvent from '@/components/PreLiveEvent.vue';
import RegisterContainer from '@/components/events/RegisterContainer.vue';
import client from '@/lib/http-client';
import questionApi from '@/api/question';
import ModalBroadcastMessage from '@/components/modals/ModalBroadcastMessage.vue';


dayjs.locale('en');
dayjs.extend(relativeTime);

const TAB_QNA = 'qna';
const TAB_POLLS = 'polls';
const TAB_VIDEO = 'video';

export default {
  name: 'EventLive',
  components: {
    LiveEventDetails,
    ModalRegistrationRequired,
    ModalAccessCodeRequired,
    EventNotFound,
    ProfileDropDown,
    QuestionList,
    PollingBox,
    ActivePolling,
    LiveEmbedCode,
    ActiveAnnouncement,
    RegisterContainer,
    PreLiveEvent,
    ModalBroadcastMessage,
  },
  props: {
    eventSlug: {
      type: String,
      default: () => null,
    },
    eventLinkCode: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      event: null,
      isFetching: false,
      isShowCountdown: true,
      isShowModalRegister: false,
      isShowModalAccessCodeRequired: false,
      isFetchingParticipant: false,
      participant: null,
      isEventNotAvailable: false,
      myIP: null,
      isShowSidebar: false,
      mobileFilterTab: TAB_VIDEO,
      filterTab: TAB_QNA,
      isShowActivePolling: false,
      isReloadTab: false,
      windowWidth: window.innerWidth,
      isMobile: false,
      totalQuestion: 0,
      isQuestionPaneVisible: false,
      isVideo: null,
      isCustomHTML: false,
      customUrl: false,

      // broadcast message
      broadcastData: null,
      isShowBroadcast: false,
    };
  },
  sockets: {
    connect() {
      this.initSocket();
    },
    events_update(payload) {
      Object.assign(this.event, payload);
      this.setBackground();
      this.checkIfCodeRequired();
      this.checkMode();

      setTimeout(() => {
        this.checkFullscreen();
        this.fetchQuestion(payload);
      }, 100);

      // set language if register is enabled
      if (payload && payload.is_enable_registration) {
        client.defaults.headers['Accept-Language'] = payload.language || 'no';
        this.$i18n.locale = payload.language || 'no';
        this.$store.dispatch('setLocale', payload.language || 'no');
      }
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : false;
      let finalSettings = qnaSettings.is_enabled;
      if (this.isQuestionClosed && this.totalQuestion === 0) {
        finalSettings = false;
      }
      if (finalSettings) this.filterTab = TAB_QNA;
      this.isQuestionPaneVisible = finalSettings;
      if (!this.isQuestionPaneVisible && !this.isPollsVisible) this.filterTab = TAB_VIDEO;
      if (!this.isQuestionPaneVisible && this.isPollsVisible) this.filterTab = TAB_POLLS;
      if (this.isQuestionPaneVisible && !this.isPollsVisible) this.filterTab = TAB_QNA;
      if (this.isQuestionPaneVisible && this.isPollsVisible) this.filterTab = TAB_QNA;

      this.checkInteractive(payload);
    },
    votings_stop() {
      this.setFilterTab('qna');
    },
    notification_add(payload) {
      console.log(payload);
      this.isShowBroadcast = true;
      this.broadcastData = payload;
    },
  },
  watch: {
    event() {
      if (this.event) this.initSocket();
    },
    isOnlyParticipant() {
      this.checkIfCodeRequired();
    },
  },
  mounted() {
    this.changeHeightOnLoad();
    this.onResizePage();
    if (window.innerWidth < 1200) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  created() {
    this.fetchEvent();
  },
  beforeDestroy() {
    this.removeBodyClass();
    this.leaveSocketRoom();
  },
  destroyed() {
    this.removeBodyClass();
  },
  methods: {
    initSocket() {
      if (!this.event) return;
      // Join Room
      this.$socket.client.emit('join', this.room);
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    changeHeightOnLoad() {
      setTimeout(() => {
        this.changeHeight();
      }, 500);
    },
    onResizePage() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
      // eslint-disable-next-line
      $(() => {
        // eslint-disable-next-line
				$(window).resize(() => {
          if (window.innerWidth < 1200) {
            this.isMobile = true;
          } else {
            this.isMobile = false;
          }
          this.changeHeight();
          this.checkFullscreen();
        });
      });
    },
    changeHeight() {
    },
    setBackground() {
      this.removeBodyClass();
      this.setBodyClass();

      // eslint-disable-next-line
			let bgImage = require('@/assets/images/backgrounds/BG_ALT_04.png');
      if (this.imageBackground) bgImage = this.imageBackground;
      document.body.style.backgroundImage = `url(${bgImage})`;
    },
    setBodyClass() {
      if (this.eventBackgroundType === 'repeat') {
        document.body.classList.add('background-repeat');
      } else {
        document.body.classList.add('background-stretch');
      }
    },
    addBodyClass() {
      this.isFetching = true;
      this.setBodyClass();
      this.setBackground();
      this.isFetching = false;
    },
    removeBodyClass() {
      document.body.classList.remove('background-repeat');
      document.body.classList.remove('background-stretch');
      document.body.style.backgroundImage = 'none';
      // document.body.style.backgroundSize = 'auto';
    },
    fetchUser() {
      this.isFetching = false;
      const callback = (response) => {
        this.isFetching = false;
        const user = response.data;
        if (user) {
          this.$store.dispatch('setUser', user);
          const participantRoom = `participant_${this.user?.id}`;
          this.$socket.client.emit('join', participantRoom);
        }
      };
      const errCallback = (err) => {
        console.error(err);
        this.isFetching = false;
      };
      authApi.getProfile(callback, errCallback);
    },

    fetchEvent(code) {
      this.isFetching = true;
      const callback = (response) => {
        this.isFetching = false;
        // Set token
        const data = response.data;

        // checking if event enabled custom html
        const isHaveCustomHtml = data.event.custom_html_url;
        const settings = data.event && data.event.settings ? JSON.parse(data.event.settings) : '';
        if (isHaveCustomHtml && settings && settings.presentation && settings.presentation.is_enabled_custom_html) {
          this.isCustomHTML = true;
          this.customUrl = data.event.custom_html_url;
          return;
        }
        this.isCustomHTML = false;

        this.fetchQuestion(data);
        if (!data.isModerator) {
          this.getLandingPage(data);
        } else {
          this.loginAsGuest(data, this.codeLogin);
        }

        if (!this.isRegisterEnabled || (this.userEmail && this.isRegisterEnabled) || ((this.codeLogin || code) && this.isRegisterEnabled)) this.fetchUser();
        if (!this.isQuestionPaneVisible && !this.isPollsVisible) this.filterTab = TAB_VIDEO;
        if (!this.isQuestionPaneVisible && this.isPollsVisible) this.filterTab = TAB_POLLS;
        if (this.isQuestionPaneVisible && !this.isPollsVisible) this.filterTab = TAB_QNA;
        if (this.isQuestionPaneVisible && this.isPollsVisible) this.filterTab = TAB_QNA;

        // responise fullscreen i frame
        setTimeout(() => {
          this.checkFullscreen();
        }, 500);
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Event'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
        this.isEventNotAvailable = true;
      };

      if (this.codeLogin || code) {
        eventApi.getByCode(this.codeLogin || code, callback, errorCallback);
      } else {
        eventApi.getByLinkCode(this.eventLinkCode, callback, errorCallback);
      }
    },
    fetchQuestion(data) {
      // eslint-disable-next-line no-nested-ternary
      const eventId = (data && data.id) || (data && data.event && data.event.id) || '';
      if (!eventId) return;
      const params = {
        page: 1,
        is_mine: false,
        is_archived: 0,
      };
      this.isFetching = true;
      const callback = (response) => {
        this.isFetching = false;
        const data = response.data;
        this.totalQuestion = data.length;
        const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : false;
        let finalSettings = qnaSettings.is_enabled;
        if (this.isQuestionClosed && this.totalQuestion === 0) {
          finalSettings = false;
        }
        if (finalSettings) this.filterTab = TAB_QNA;
        this.isQuestionPaneVisible = finalSettings;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      questionApi.list(eventId, params, callback, errorCallback);
    },
    getTotalQuestion(length) {
      this.totalQuestion = length;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : false;
      let finalSettings = qnaSettings.is_enabled;
      if (this.isQuestionClosed && this.totalQuestion === 0) {
        finalSettings = false;
      }
      if (finalSettings) this.filterTab = TAB_QNA;
      this.isQuestionPaneVisible = finalSettings;
    },
    getLandingPage(data) {
      const tokenData = data.token;
      if (tokenData) {
        // this.$store.dispatch('loginOnTheFly', tokenData);
        this.$store.dispatch('loginOnTheFlyAttendee', tokenData);
      }

      // Set event
      const event = data.event;
      if (event) {
        this.event = event;
        this.checkVideoUrl();
        document.title = event.name;
        this.addBodyClass();
        this.checkIfCodeRequired();
        this.checkMode();

        this.$i18n.locale = event.language;
        document.querySelector('html').setAttribute('lang', event.language);
        this.$store.dispatch('setLocale', event.language);
      }
    },
    loginAsGuest(data, code) {
      data.m_code = code;
      this.$store
        .dispatch('loginGuest', data)
        .then(() => {
          this.$vs.loading.close();
          this.isLoggingIn = false;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t('Login Error'),
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isLoggingIn = false;
        });
    },
    checkFullscreen() {
      if (this.isFullscreen) {
        const aspectRatio = 16 / 9; // Desired aspect ratio
        const containerWidth = this.$refs.videoEmbed.$el.offsetWidth || 0;
        const containerHeight = window.innerHeight - 220; // Get viewport height
        // Calculate the width based on the aspect ratio and container height
        const width = aspectRatio * containerHeight;
        // Calculate the maximum width to prevent overflow
        const maxWidth = Math.min(containerWidth, width);
        // Get the iframe element within the component
        const iframeElement = this.$refs.videoEmbed.$el.querySelector('iframe');
        // Check if iframeElement exists
        if (this.isFullscreen) {
          if (iframeElement) {
          // Set the width and height of the iframe
            iframeElement.style.width = `${maxWidth}px`;
            iframeElement.style.height = `${maxWidth / aspectRatio}px`;
          }
        }
      } else {
        const iframeElement = this.$refs.videoEmbed.$el.querySelector('iframe');
        // Check if iframeElement exists
        if (iframeElement) {
          iframeElement.style.width = '100%';
          iframeElement.style.height = '100%';
        }
      }
    },
    checkMode() {
      if (this.isInteractiveMode) {
        this.mobileFilterTab = 'qna';
        this.filterTab = 'qna';
        if (!this.isQuestionPaneVisible && this.isPollsVisible) {
          this.mobileFilterTab = 'polls';
          this.filterTab = 'polls';
        }
        if (this.isQuestionPaneVisible && !this.isPollsVisible) {
          this.mobileFilterTab = 'qna';
          this.filterTab = 'qna';
        }
      }
    },
    showCountdown(toggle) {
      this.isShowCountdown = toggle;
    },
    checkIfCodeRequired() {
      setTimeout(() => {
        if (this.isOnlyParticipant) {
          const accessCodeSaved = localStorage.getItem(this.event.slug);
          if (accessCodeSaved !== undefined) {
            const isSame = accessCodeSaved === this.eventAccessCode;
            if (!isSame) {
              this.isShowModalAccessCodeRequired = true;
            }
          } else {
            this.isShowModalAccessCodeRequired = true;
          }
        } else if (this.isOnlyParticipant) {
          this.checkAccessCodeParticipant();
        } else if (!this.isOnlyParticipant) {
          this.isShowModalAccessCodeRequired = false;
        }
      }, 200);
    },
    setParticipant(participant) {
      this.participant = participant;
    },
    setLiveUrl() {
      const LAST_SESSION_URL = 'LAST_SESSION_URL';
      const lastUrl = window.location.href;
      localStorage.setItem(LAST_SESSION_URL, lastUrl);
    },
    checkAccessCodeParticipant() {
      const accessCodeSaved = localStorage.getItem(this.event.slug);
      if (accessCodeSaved !== undefined) {
        const isSame = this.participant.code === accessCodeSaved;
        if (!isSame) this.isShowModalAccessCodeRequired = true;
      } else {
        this.isShowModalAccessCodeRequired = true;
      }
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
    showProfile() {
      this.isShowSidebar = false;
      if (this.$refs.profileDropdown) {
        this.$refs.profileDropdown.showProfile();
      }
    },
    showMyQuestion() {
      this.isShowSidebar = false;
      if (this.$refs.profileDropdown) {
        this.$refs.profileDropdown.showMyQuestion();
      }
    },
    showHome() {
      this.isShowSidebar = false;
      if (this.$refs.profileDropdown) {
        this.$refs.profileDropdown.closeProfile();
        this.$refs.profileDropdown.closeMyQuestion();
      }
    },
    switchEvent() {
      localStorage.removeItem('access_token_attendee');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('LAST_SESSION_URL');
      setTimeout(() => {
        this.$router.push('/login');
      }, 200);
    },
    joinEvent() {
      const params = {
        ip_address: this.myIP,
        browser: this.myIP,
        event_slug: this.eventSlug,
      };
      const callback = () => {
      };
      const errorCallback = () => {
      };
      eventApi.join(params, callback, errorCallback);
    },
    setFilterTab(tab) {
      this.filterTab = tab;

      if (tab === 'polls') this.mobileFilterTab = tab;
    },
    closeModalAccessCode() {
      this.isShowModalAccessCodeRequired = false;
      this.fetchUser();
    },
    checkInteractive(event) {
      // redirect to interactive if embedcode is false
      const embedCode = event && event.embed_code;
      if (embedCode) {
        this.isVideoURL(embedCode).then((isVideo) => {
          const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
          const match = embedCode ? embedCode.match(regex) : '';
          this.isVideo = isVideo;
          if (this.isVideo || match) {
            console.log('Embed Code Valid');
          } else if (!this.$route.query.is_interactive) {
            const routeData = this.$router.resolve({
              name: 'EventLive',
              params: {
                eventLinkCode: event.link_code_value,
              },
            });
            const targetUrl = `${routeData.href}?is_interactive=1`;
            window.open(targetUrl, '_self');
          }
        });
      }
    },
    isVideoURL(url) {
      const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.flv', '.mkv', '.wmv'];
      const urlExtension = url.split('.').pop();
      if (videoExtensions.includes(`.${urlExtension}`)) {
        return Promise.resolve(true);
      }

      return fetch(url, { method: 'HEAD' })
        .then((response) => {
          const contentType = response.headers.get('Content-Type');
          return contentType && contentType.startsWith('video/');
        })
        .catch(() => false);
    },
    checkVideoUrl() {
      const embedCode = this.event && this.event.embed_code;
      if (embedCode) {
        this.isVideoURL(embedCode).then((isVideo) => {
          const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
          const match = embedCode ? embedCode.match(regex) : '';
          this.isVideo = isVideo;
          if (this.isVideo || match) {
            console.log('Embed Code Valid');
          } else if (!this.$route.query.is_interactive) {
            const routeData = this.$router.resolve({
              name: 'EventLive',
              params: {
                eventLinkCode: this.event.link_code_value,
              },
            });
            const targetUrl = `${routeData.href}?is_interactive=1`;
            window.open(targetUrl, '_self');
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
      isEventAdminModerator: 'isEventAdminModerator',
    }),
    userLang() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang;
    },
    userEmail() {
      const lang = this.user && this.user.email ? this.user.email : '';
      return lang;
    },
    isOwner() {
      const isOwner = this.user && this.event && this.event.user && this.user.id === this.event.user.id;
      return isOwner;
    },
    userName() {
      const name = this.user && this.user.name ? `${this.user.name}` : 'Home';
      return name;
    },
    room() {
      const eventId = this.event ? this.event.id : '';
      const room = `event_${eventId}`;
      return room;
    },
    eventSettings() {
      const settings = this.event && this.event.settings ? JSON.parse(this.event.settings) : '';
      return settings;
    },
    isPollsVisible() {
      const pollsSettings = this.eventSettings && this.eventSettings.polls ? this.eventSettings.polls : null;
      return pollsSettings.is_enabled;
    },
    isQuestionClosed() {
      let isClosed = false;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings && qnaSettings.is_closed) isClosed = true;
      return isClosed;
    },
    isBoxBackgroundEnabled() {
      let isEnabled = false;
      const styleSettings = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      if ((styleSettings && styleSettings.is_enabled_box_bg) || this.isFullscreen) isEnabled = true;
      return isEnabled;
    },
    eventBackgroundType() {
      let type = 'stretch';
      const styleSettings = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      if (styleSettings && styleSettings.background_type) type = styleSettings.background_type;
      return type;
    },
    imagePreBackground() {
      let image = '';
      const styleSettings = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      image = styleSettings && styleSettings.event_image;
      return image;
    },
    imageBackground() {
      let image = '';
      const styleSettings = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      image = styleSettings && styleSettings.background;
      return image;
    },
    isEmbedCodeExist() {
      const embedCode = this.event && this.event.embed_code;
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = embedCode.match(regex);
      this.checkVideoUrl();

      if (match || this.isVideo) {
        return true;
      }
      return false;
    },
    isEventPublished() {
      const isPublished = this.event ? this.event.is_published : true;
      return isPublished;
    },
    isLiveVideoVisible() {
      const isVisible = !this.isShowCountdown && !!this.event && !!this.event.embed_code;
      return isVisible;
    },
    isCountdownVisible() {
      return this.isShowCountdown;
    },
    isLoginRequired() {
      const isRequired = this.eventSettings.is_login_required !== undefined ? this.eventSettings.is_login_required : true;
      return isRequired;
    },
    eventAccessCode() {
      const accessCode = this.event.code;
      return accessCode;
    },
    isOnlyParticipant() {
      const isOnlyParticipantValid = this.event ? !!this.event.is_only_participants : false;
      return isOnlyParticipantValid;
    },
    isPreview() {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      const isPreview = !!url.searchParams.get('preview') || false;
      return isPreview;
    },
    codeLogin() {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      const code = url.searchParams.get('code');
      return code;
    },
    isInteractiveMode() {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      const isInteractiveValid = url.searchParams.get('is_interactive') === '1' || false;
      return isInteractiveValid;
    },
    fontHeadingColor() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_color ? this.eventSettings.style.font_color : null;
      const fontHeadingColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_heading_color ? this.eventSettings.style.font_heading_color : fontColor;
      if (!fontHeadingColor) return '';
      const styles = {
        color: `${fontHeadingColor}`,
      };
      return styles;
    },
    isParticipant() {
      return !!this.participant;
    },
    myBrowser() {
      return this.$browserDetect && this.$browserDetect.meta ? `${this.$browserDetect.meta.name} - ${this.$browserDetect.meta.ua} - ${this.$browserDetect.meta.version}` : null;
    },
    formatEventTime() {
      if ((this.userLang && this.userLang === 'no') || (this.event && this.event.language === 'no')) dayjs.locale(noLocale);
      else dayjs.locale('en');

      let timeLabel = this.event && this.event.start_date ? dayjs(this.event.start_date).format('dddd DD MMMM YYYY') : '';
      if ((this.userLang && this.userLang === 'no') || (this.event && this.event.language === 'no')) timeLabel = this.event && this.event.start_date ? dayjs(this.event.start_date).format('dddd, D. MMMM YYYY') : '';
      return timeLabel;
    },
    isRegisterEnabled() {
      return this.event && this.event.is_enable_registration ? this.event.is_enable_registration : false;
    },
    isHybrid() {
      return this.event && this.event.is_hybrid ? this.event.is_hybrid : false;
    },
    isFullscreen() {
      const styleSettings = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      const isFullscreen = styleSettings && styleSettings.is_fullscreen;
      return isFullscreen;
    },
    eventFont() {
      const font = this.eventSettings && this.eventSettings.style && this.eventSettings.style.display_font ? this.eventSettings.style.display_font : 'Roboto';
      return `font-family: ${font};`;
    },
    colorPage() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color_event ? this.eventSettings.style.is_dark_color_event : false;
      return `color: ${fontColor ? 'white' : 'black'}`;
    },
    isShowMenuSwitchQuestion() {
      const isMenu = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      if (!Object.hasOwnProperty.call(this.eventSettings.style, 'is_show_switch_question')) return true;
      return isMenu && isMenu.is_show_switch_question ? isMenu.is_show_switch_question : false;
    },
  },
};
</script>
