<template>
	<div class="polling-box-container">
    <!-- Fetching -->
    <div class="py-10 text-center" v-if="isFetching">
      <div class="is-loading large dark"></div>
    </div>

    <!-- Content -->
    <div class="poll-box-wrapper" v-if="!isFetching" :style="colorPage">
      <!-- Question and Options -->
      <div class="polling-item" v-if="!isSaved && !isAlreadyVoted"   :style="isFullscreen ? 'height: calc(100vh - 230px); overflow-y: auto;': ''">
        <div class="flex justify-between font-bold mb-4">
          {{ voting ? voting.name : '' }}
        </div>
        <!-- Single / Multiple Options -->
        <div class="mt-4" v-if="isMultipleOptions || isSingleOption">
          <div
            class="polling-item-option multiple-selection"
            :class="`${selectedOptionIds.includes(option.id) ? 'selected' : ''}`"
            v-for="(option, index) in options"
            :key="index"
            @click="setOptionIds(option)"
            >
            <div
              class="polling-item-option__selected"
              :class="`${selectedOptionIds.includes(option.id) ? 'filled' : ''}`">
            </div>
            <div class="polling-item-option__name">
              {{ option.name }}
            </div>
          </div>
        </div>

        <div class="mt-4" v-if="isOpenTextOption">
          <vs-input v-filter-input
            data-vv-validate-on="blur"
            v-validate="'required|min:6'"
            type="text"
            name="answer"
            :placeholder="$t('Type here')"
            v-model="openTextAnswer"
            @input="onChangeOpenTextAnswer"
            class="w-full"
            :disabled="!!isVotingLocked"
          />
          <span class="text-danger text-sm">{{ $t(errors.first('answer')) }}</span>
        </div>
      </div>

      <!-- Results -->
      <results-poll
        v-if="event && isAlreadyVoted && !isHideLiveResult && voting"
        :event-data="event"
        :live-poll="voting"
        :is-show-lock-information="false"
        class="polling-box-result"
      />

      <!-- Actions -->
      <div v-if="!isVotingLocked">
        <div class="flex justify-between mt-4" v-if="!isSaved && voting && !isAlreadyVoted">
          <user-selector
            :event="event"
            :is-show-voting-as-label="true"
            @setSelectedUser="setSelectedUser"
            >
          </user-selector>
          <div class="flex items-center">
            <button
              class="button-primary-mobile"
              :class="{ 'is-loading': isSaving, 'button-primary-mobile-hover': !isAnswerred || isSaving }"
              @click="submit()"
              >
              <div class="flex items-center">
                {{ $t('Send') }}
                <svg class="w-6 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
              </div>
            </button>
          </div>
        </div>

        <!-- Thanks -->
        <div class="p-4 text-center" v-if="(isSaved || isAlreadyVoted) && isHideLiveResult">
          {{ $t("Thank you for your submission") }}
        </div>
      </div>

      <!-- No active voting -->
      <div class="p-4 text-center" v-if="!voting">
        {{ $t("No active voting") }}
      </div>
    </div>
	</div>
</template>

<script>
import votingApi from '@/api/voting';
import UserSelector from '@/components/sender/UserSelector.vue';
import ResultsPoll from '@/components/polls/ResultsPoll.vue';

const QUESTION_TYPE_MULTIPLE_CHOICE = 'multiple_choice';
const QUESTION_TYPE_OPEN_TEXT = 'open_text';

export default {
  components: {
    UserSelector,
    ResultsPoll,
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    participant: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
    poll: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selectedOptionIds: [],
      openTextAnswer: '',
      isFetching: false,
      isSaving: false,
      isSaved: false,
      voting: null,
      selectedUser: null,
      isAlreadyVoted: false,
    };
  },
  sockets: {
    votings_active(voting) {
      this.voting = voting;
      this.isSaved = false;
      this.participantCheck();
    },
    votings_stop() {
      this.voting = null;
      this.participantCheck();
      this.resetOptionIds();
    },
    votings_update(voting) {
      if (this.voting && this.voting.id === voting.id) this.voting = voting;
      this.participantCheck();
    },
    votings_results(voting) {
      if (this.voting && this.voting.id === voting.id) this.voting = voting;
    },
    votings_delete(voting) {
      if (this.voting && this.voting.id === voting.id) {
        this.voting = null;
        this.resetOptionIds();
      }
    },
  },
  mounted() {
    this.fetchActiveVoting();
  },
  created() {
  },
  destroyed() {
  },
  watch: {
    // voting() {
    //   this.checkIfAlreadyVoted(false);
    // },

  },
  computed: {
    isAnswerred() {
      const isOptionSelected = (this.isMultipleOptions || this.isSingleOption) && this.selectedOptionIds.length > 0;
      const isOpenTextAnswerred = this.isOpenTextOption && this.openTextAnswer;
      const isValid = isOptionSelected || isOpenTextAnswerred;
      return isValid;
    },
    isMultipleOptions() {
      return this.voting && this.voting.is_multiple_options && this.voting.type === QUESTION_TYPE_MULTIPLE_CHOICE;
    },
    isSingleOption() {
      return this.voting && !this.voting.is_multiple_options && this.voting.type === QUESTION_TYPE_MULTIPLE_CHOICE;
    },
    isOpenTextOption() {
      return this.voting && this.voting.type === QUESTION_TYPE_OPEN_TEXT;
    },
    isVotingLocked() {
      return this.voting && this.voting.is_locked;
    },
    isHideLiveResult() {
      return this.voting && this.voting.is_hide_live_result;
    },
    options() {
      return this.voting ? this.voting.options : [];
    },
    votingAnswerInputLength() {
      let inputLength = 160;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings) inputLength = qnaSettings.input_max_length;
      return inputLength;
    },
    colorPage() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color_event ? this.eventSettings.style.is_dark_color_event : false;
      return `color: ${fontColor ? 'white' : 'black'}`;
    },
    isFullscreen() {
      const styleSettings = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      const isFullscreen = styleSettings && styleSettings.is_fullscreen;
      return isFullscreen;
    },
  },
  methods: {
    onChangeOpenTextAnswer() {
      console.log(this.openTextAnswer.length > this.votingAnswerInputLength);
      if (this.openTextAnswer.length > this.votingAnswerInputLength) {
        this.openTextAnswer = this.openTextAnswer.substring(0, this.votingAnswerInputLength);
      }
    },
    fetchActiveVoting() {
      this.isFetching = true;
      const callback = (response) => {
        const data = response.data;
        if (data) {
          this.voting = data;

          this.participantCheck();
        }
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      votingApi.getActive(this.event.id, callback, errorCallback);
    },
    participantCheck(isReload = true) {
      if (!this.voting) {
        this.isFetching = false;
        return;
      }

      if (isReload) this.isFetching = true;
      const callback = (response) => {
        this.isFetching = false;
        const data = response.data;
        if (data) {
          this.isAlreadyVoted = data.is_already_vote;
        }
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      votingApi.participantCheck(this.voting.id, callback, errorCallback);
    },
    generateInitial() {
      const words = this.poll.user && this.poll.user.name ? this.poll.user.name.split(' ') : 'AN';
      const initial = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
      return initial;
    },
    resetOptionIds() {
      this.selectedOptionIds = [];
    },
    setOptionIds(option) {
      // Check if already voted
      if (this.isAlreadyVoted || this.isVotingLocked) return;

      if (this.isMultipleOptions) {
        const index = this.selectedOptionIds.findIndex(curr => curr === option.id);
        if (index === -1) {
          this.selectedOptionIds.push(option.id);
        } else {
          this.selectedOptionIds.splice(index, 1);
        }
      } else {
        this.resetOptionIds();
        this.selectedOptionIds.push(option.id);
      }
    },
    setSelectedUser(user) {
      this.selectedUser = user;
    },
    submit() {
      // Check if already voted
      if (this.isAlreadyVoted) return;

      const params = {
        sender_name: this.selectedUser ? this.selectedUser.name : 'Anonymous',
      };

      if (this.isOpenTextOption) {
        params.answer = this.openTextAnswer;
      } else {
        params.voting_option_ids = JSON.stringify(this.selectedOptionIds);
      }

      this.isSaving = true;
      const callback = (response) => {
        const data = response.data;
        if (data) {
          this.voting = data;
          this.participantCheck();
        }
        this.resetSelected();
        this.isSaving = false;
        this.isSaved = true;
      };
      const errorCallback = () => {
        this.isSaving = false;
        this.isSaved = true;
      };
      votingApi.vote(this.voting.id, params, callback, errorCallback);
    },
    resetSelected() {
      this.selectedOptionIds = [];
    },
    checkIfAlreadyVoted(isReloading = true) {
      this.participantCheck(isReloading);
    },
  },
};
</script>
